import React, { createContext, useState, useEffect } from 'react'

import { getThemeNumberBasedOnMtmContentValue } from 'utils/getThemeNumber'
import { getMtmContentValueFromUrl } from 'utils/getMtmContentValueFromUrl'
import {
  getSavedMtmContentValue,
  saveMtmContentValue,
} from 'utils/handleMtmContentValueLocalStorage'

type LayoutProviderProps = {
  children: React.ReactNode
}

type LayoutContextType = {
  themeNumber: number
  setIsLoading: (loading: boolean) => void
  isLoading: boolean
}

const defaultContextValues = {
  themeNumber: 2,
  setIsLoading: () => null,
  isLoading: false,
}

export const LayoutContext =
  createContext<LayoutContextType>(defaultContextValues)

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [themeNumber, setThemeNumber] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // const mtmContentValue = getMtmContentValueFromUrl()

    // let chosenThemeNumber: number

    // if (mtmContentValue) {
    //   saveMtmContentValue(mtmContentValue)
    //   chosenThemeNumber = getThemeNumberBasedOnMtmContentValue(mtmContentValue)
    // } else {
    //   const savedMtmContentValue = getSavedMtmContentValue()

    //   chosenThemeNumber = getThemeNumberBasedOnMtmContentValue(
    //     savedMtmContentValue!
    //   )
    // }

    // setTimeout(() => {
    //   setThemeNumber(chosenThemeNumber)
    // })

    setTimeout(() => {
      setIsLoading(false)
    }, 650)
  }, [])

  return (
    <LayoutContext.Provider
      value={{
        themeNumber,
        setIsLoading,
        isLoading,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutContext
